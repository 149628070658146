<template>
  <!-- 高级搜索 -->
  <el-dialog
    :visible.sync="centerDialogVisible"
    width="50%"
    top="0"
    center
    title="问卷题目编辑"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <el-form-item label="题目内容" prop="questionName" class="areaBox">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入内容"
          v-model="form.questionName"
          maxlength="250"
        ></el-input>
      </el-form-item>
      <el-form-item label v-if="form.questionType == 10 || form.questionType == 20">
        <div>
          <div
            v-for="(item1, index) in form.optionBOList"
            :key="index"
            style="display: flex; align-items: center; margin-bottom: 18px"
          >
            <el-form-item
              :prop="'optionBOList[' + index + '].optionName'"
              class="optionName"
            >
              {{ optionFM(index) }}：
              <el-input
                v-model="item1.optionName"
                style="width: 220px; margin-right: 10px"
              ></el-input>
              <!-- <el-upload
                  :on-change="
                    (res, file) => {
                      return handleAvatarSuccess2(res, file, index);
                    }
                  "
                  :before-upload="$beforeAvatarUpload"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                     item1.optionImgUrl ||
                        require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload> -->
              <el-button
                v-if="index > 1"
                class="de"
                type="text"
                @click="delOpenList(index)"
                >删除选项</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label
        v-if="
          (form.questionType == 10 || form.questionType == 20) &&
          form.optionBOList.length < 6
        "
      >
        <el-button @click="addFromOpenList">添加选项</el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="bgc-bv" @click="centerDialogVisible = false"
        >取 消</el-button
      >
      <el-button class="bgc-bv" @click="addOptions">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name: "ChestLotteryList",
  components: {
    // Empty,
  },
  mixins: [List],
  data() {
    return {
      centerDialogVisible: false,
      showtitle: false,
      paperList: [],
      form: {
        questionTitle: "",
        questionAnswer2: [],
        optionList: [{ mins: "" }, { mins: "" }],
        questionAnswer: "",
        questionScore: "",
      },
      parperId: "",
      ruleForm: {},
      rules: {
        questionTitle: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
        questionScore: [
          { required: true, message: "请输入题目分数", trigger: "blur" },
        ],
        questionAnswer: [
          { required: true, message: "请选择一个正确答案项", trigger: "blur" },
        ],
        "optionList[0].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
        "optionList[1].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
        "optionList[2].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
        "optionList[3].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
        "optionList[4].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
        "optionList[5].mins": [
          { required: true, message: "请输入答案", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {
    // console.log(document.getElementById("list-box").offsetHeight);
    // const height = document.getElementById('list-box').offsetHeight;
  },
  computed: {},
  methods: {
    init() {},
    showPopUp(questionId) {
      this.centerDialogVisible = true;
      // this.showtitle = false;
      this.$nextTick(function () {
        this.getsingle(questionId);
      });
    },
    // 获取单个数据
    getsingle(questionId) {
      this.$post("/survey/sr-paper/getQuestionInfo", { questionId })
        .then((ret) => {
          console.log("ret: ", ret);
          // const form = ret.data;
          this.form = ret.data;
          console.log(ret.data.questionType);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //ABCDEF
    optionFM(msg) {
      if (msg == 0) {
        return "A";
      } else if (msg == 1) {
        return "B";
      } else if (msg == 2) {
        return "C";
      } else if (msg == 3) {
        return "D";
      } else if (msg == 4) {
        return "E";
      } else if (msg == 5) {
        return "F";
      }
    },
    addOptions() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.showtitle = false;
          const that = this;
          let parmar = {
            questionOrder: that.form.questionOrder,
            paperId: that.form.paperId,
            questionName: that.form.questionName,
            questionType: that.form.questionType,
            questionId: that.form.questionId,
            optionDTOList: that.form.optionBOList,
          };
          that
            .$post("/survey/sr-paper/saveOrUpdateQuestion", parmar)
            .then((ret) => {
              if (ret.status == 0) {
                that.centerDialogVisible = false;
                that.$emit("getnewdata");
                that.$emit("subShows", true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //单选题图片上传
    handleAvatarSuccess2(res, fiie, index) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("folder ", "LECTURER");
      formData.append("isPublic ", true);
      const that = this;
      that
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          that.form.optionBOList[index].optionImg = result.data.fileKey || "";
          that.form.optionBOList[index].optionImgUrl =
            result.data.fileURL || "";
        })
        .catch(() => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    delOpenList(index) {
      this.form.optionBOList.splice(index, 1);
      this.$forceUpdate();
    },
    addFromOpenList() {
      let data = {
        optionName: "",
      };
      this.form.optionBOList.push(data);
      this.$forceUpdate();
    },
    docancle() {
      this.centerDialogVisible = false;
      this.empty();
    },
    empty() {
      this.form = {
        questionType: 0,
        optionList: [{ mins: "" }, { mins: "" }],
      };
      this.$refs["form"].resetFields();
      this.ruleForm = {};
    },
    closeDialog() {
      this.centerDialogVisible = false;
      this.empty();
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  line-height: 32px;
  height: 32px;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        .number {
          padding-left: 1rem;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: 9.375rem;
        }
      }
    }
  }
}
.tixing {
  font-size: 14px;
  height: fit-content;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.showtitle {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}
.areaBox {
  .el-textarea {
    .el-textarea__inner {
      min-height: 150px !important;
      resize: none;
    }
  }
}
.optionName {
  > .el-form-item__content {
    display: flex !important;
    align-items: center !important;
  }
}
</style>
<style lang="less" scoped>
.addlist {
  h3 {
    display: flex;
    border-left: 3px solid rgb(92, 107, 232);
    span {
      margin-left: 20px;
    }
    margin-bottom: 30px;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div {
    margin-bottom: 5px;
  }
  > div:last-child {
    border: 0;
    margin-bottom: 0;
  }
}
.form-box {
  padding: 10px 20px;
  .form {
    width: 70%;
    padding: 20px;
  }
  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}
.btn-box {
  padding: 20px 20%;
  display: flex;
  justify-content: space-around;
  width: 70%;
  button {
    padding: 12px 30px;
  }
}
.el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item__content .img-el-upload /deep/.el-upload {
  margin-left: 15px;
  width: 30px;
  height: 30px !important;
}
</style>
